import React, { useState } from 'react';
import useCompanyInfo from './useCompanyInfo';

const ClaimBonusPopup = () => {
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');

    const { telefonoWhatsApp } = useCompanyInfo() || {};

    const companyPhone = telefonoWhatsApp || "Teléfono no disponible";

    const handleSubmit = (e) => {
        e.preventDefault();
        const message = `Hola! Quiero reclamar mi bono. \nMis datos son: \n\n- Nombre: ${nombre}, \n- Teléfono: ${telefono}, \n- Email: ${email}`;
        const whatsappURL = `https://wa.me/${companyPhone}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <div className="flex items-center justify-center overflow-y-auto w-full h-full">
            <div className="bg-white max-w-full md:max-w-5xl w-full flex flex-col lg:flex-row">
                <div className="overflow-hidden">
                    <img
                        src="../images/bono.jpg"
                        alt="Bono"
                        className="w-full h-full rounded-l-lg object-cover"
                    />
                </div>
                <div className=" md:ml-4 p-4 flex flex-col justify-center bg-white">
                    <div className='flex flex-col space-y-3 mb-2 leading-snug'>
                        <h2 className="text-md md:text-lg font-black text-green-800 text-center md:text-left">Gratis! Bono De Bienvenida</h2>
                        <p className="text-gray-700 text-xs lg:text-sm md:text-left">Por compras desde <span className='font-bold'>$550.000 </span>pesos obtén un bono de $50.000 pesos. Por compras desde <span className='font-bold'>$1.100.000</span> obtén un bono de $100.000 pesos.</p>
                        <p className="text-gray-700 text-xs lg:text-sm md:text-left"><span className='mr-1 font-bold'>¡No pierdas esta oportunidad!</span>Regístrate ahora para obtener tu bono. Completa el formulario y te contactaremos por WhatsApp para finalizar tu solicitud.!</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-2">
                            <input
                                type="text"
                                id="nombre"
                                className="mt-1 block w-full border border-gray-300 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 px-2 py-1"
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                                placeholder="Nombre o Razón Social"
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <input
                                type="tel"
                                id="telefono"
                                className="mt-1 block w-full border border-gray-300 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 px-2 py-1"
                                value={telefono}
                                onChange={(e) => setTelefono(e.target.value)}
                                placeholder="Teléfono"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="email"
                                id="email"
                                className="mt-1 block w-full border border-gray-300 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 px-2 py-1"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                            />
                        </div>
                        <button
                            id='reclamar-bono'
                            type="submit"
                            className="bg-green-700 text-white px-4 py-2 rounded-md hover:bg-green-800 transition duration-200 w-full"
                        >
                            Reclamar Bono
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ClaimBonusPopup;
